import { ChainId, Currency, CurrencyAmount, ETHER, Token, TokenAmount, WETH } from '@intercroneswap/v2-sdk';

export function wrappedCurrency(currency: Currency | undefined, chainId: ChainId | undefined): Token | undefined {
  return chainId && currency === ETHER ? WETH[chainId] : currency instanceof Token ? currency : undefined;
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined,
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined;
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined;
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH[token.chainId])) return ETHER;
  return token;
}

export function isOneTokenWETH(token0: Token, token1: Token): [boolean, Token | undefined] {
  if (token0.equals(WETH[token0.chainId]) || token1.equals(WETH[token1.chainId])) {
    return [true, WETH[token0.chainId]];
  }
  return [false, undefined];
}
